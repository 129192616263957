import React, { useState } from "react";
import Button from "../../../components/Button";

const SCREEN_NUMBERS = { min: 1, max: 1 };

const Navigation = ({
  currentForm,
  onCancel,
  isEditScreen,
  onSaveChanges,
  isSaving,
}) => {
  const handleSaveClick = () => {
    onSaveChanges();
  };

  if (isEditScreen) {
    return (
      <div className="flex gap-4 mt-10">
        <Button type="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          disabled={isSaving}
          onClick={handleSaveClick}
          className="disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Save Changes
        </Button>
      </div>
    );
  }

  return (
    <div className="flex gap-4 mt-10">
      {currentForm === SCREEN_NUMBERS.min && (
        <Button type="secondary" onClick={onCancel}>
          Back to Projects
        </Button>
      )}

      {currentForm === SCREEN_NUMBERS.max && (
        <Button
          disabled={isSaving}
          type="submit"
          onClick={handleSaveClick}
          className="disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Create Project
        </Button>
      )}
    </div>
  );
};

export default Navigation;
