import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Input from "../../../components/Input";
import FileUpload from "../../../components/Fileupload";
import Navigation from "../Navigation";

import { addClass } from "../../../utils/css-helper";
import {
  isValidApplicationId,
  isValidAppName,
  isValidHttpUrl,
} from "../../../utils/validation-helper";

import {
  createProject,
  getProjectData,
  updateProject,
} from "../../../api/project";
import Tooltip from "../../../components/Tooltip";
import Button from "../../../components/Button";
import Loader from "../../../components/Loader";

const platforms = [
  {
    name: "Android",
    id: "android",
    icon: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="200" height="200" viewBox="-147 -70 294 345">
    <g fill="#a4c639">
    <use strokeWidth="14.4" xlink:href="#b" stroke="#FFF"/>
    <use xlink:href="#a" transform="scale(-1,1)"/>
    <g id="a" stroke="#FFF" strokeWidth="7.2">
    <rect rx="6.5" transform="rotate(29)" height="86" width="13" y="-86" x="14"/>
    <rect id="c" rx="24" height="133" width="48" y="41" x="-143"/>
    <use y="97" x="85" xlink:href="#c"/>
    </g>
    <g id="b">
    <ellipse cy="41" rx="91" ry="84"/>
    <rect rx="22" height="182" width="182" y="20" x="-91"/>
    </g>
    </g>
    <g stroke="#FFF" strokeWidth="7.2" fill="#FFF">
    <path d="m-95 44.5h190"/><circle cx="-42" r="4"/><circle cx="42" r="4"/>
    </g>
    </svg>`,
  },
  {
    name: "iOS",
    id: "ios",
    disabled: true,
    icon: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" width="200" height="200" viewBox="0 0 800 800" enable-background="new 0 0 800 800" xml:space="preserve">
    <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="400.05" y1="798.7717" x2="400.05" y2="-1.2283" gradientTransform="matrix(1 0 0 -1 0 798.7717)">
        <stop offset="0" style="stop-color:#18BFFB"/>
        <stop offset="1" style="stop-color:#2072F3"/>
    </linearGradient>
    <path fill="url(#SVGID_1_)" d="M638.4,0H161.6C72.3,0,0,72.3,0,161.6v476.9C0,727.7,72.3,800,161.6,800h476.9  c89.2,0,161.6-72.3,161.6-161.6V161.6C800,72.3,727.7,0,638.4,0z"/>
    <path fill="#FFFFFF" d="M396.6,183.8l16.2-28c10-17.5,32.3-23.4,49.8-13.4s23.4,32.3,13.4,49.8L319.9,462.4h112.9  c36.6,0,57.1,43,41.2,72.8H143c-20.2,0-36.4-16.2-36.4-36.4c0-20.2,16.2-36.4,36.4-36.4h92.8l118.8-205.9l-37.1-64.4  c-10-17.5-4.1-39.6,13.4-49.8c17.5-10,39.6-4.1,49.8,13.4L396.6,183.8L396.6,183.8z M256.2,572.7l-35,60.7  c-10,17.5-32.3,23.4-49.8,13.4S148,614.5,158,597l26-45C213.4,542.9,237.3,549.9,256.2,572.7L256.2,572.7z M557.6,462.6h94.7  c20.2,0,36.4,16.2,36.4,36.4c0,20.2-16.2,36.4-36.4,36.4h-52.6l35.5,61.6c10,17.5,4.1,39.6-13.4,49.8c-17.5,10-39.6,4.1-49.8-13.4  c-59.8-103.7-104.7-181.3-134.5-233c-30.5-52.6-8.7-105.4,12.8-123.3C474.2,318.1,509.9,380,557.6,462.6L557.6,462.6z"/>
    </svg>`,
  },
  // {
  //     name: 'Windows',
  //     id: 'windows',
  //     icon: `<svg width="200" height="200" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4875 4875"><path fill="#0078d4" d="M0 0h2311v2310H0zm2564 0h2311v2310H2564zM0 2564h2311v2311H0zm2564 0h2311v2311H2564"/></svg>`
  // }, {
  //     name: 'macOS',
  //     id: 'macos',
  //     icon: `<svg width="200" height="200" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
  //     <path fill="#b3b3b3" fillRule="evenodd" d="M14.1222584,4.68222045 C15.4725648,4.68222045 16.9029698,5.42546577 17.9224981,6.71020766 C14.5825789,8.56144598 15.1252231,13.3836655 18.5,14.6731574 C18.0354768,15.7134008 17.8127331,16.1783979 17.2147121,17.0986421 C16.3803503,18.383384 15.2040857,19.9828739 13.7462394,19.9968738 C12.4508155,20.0091237 12.1183069,19.1443792 10.3600904,19.1541291 C8.60212114,19.1641291 8.23549641,20.0118737 6.93982533,19.9998738 C5.48197904,19.9861239 4.36702453,18.541633 3.53216835,17.2571411 C1.19793322,13.6649138 0.954175977,9.44944037 2.39372811,7.20895451 C3.41671741,5.61571456 5.03031122,4.68347045 6.54798433,4.68347045 C8.09309867,4.68347045 9.06441935,5.54046504 10.3417963,5.54046504 C11.5811016,5.54046504 12.335859,4.68222045 14.1222584,4.68222045 Z M13.7301702,0 C13.9091563,1.21549233 13.416203,2.40498482 12.767255,3.24697951 C12.0723243,4.14897382 10.8747989,4.84821941 9.71757008,4.81196964 C9.50644566,3.64872698 10.0490898,2.45198453 10.706196,1.64498962 C11.4300512,0.755745231 12.6700982,0.0739995331 13.7301702,0 Z"/>
  //   </svg>`
  // },
  // {
  //     name: 'Linux',
  //     id: 'linux',
  //     icon: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" width="200" height="200" viewBox="0 2 48 48" enable-background="new 0 2 48 48" xml:space="preserve">
  //     <polygon fill="#ECEFF1" points="20.1,18.2 20.2,20.5 18.6,23.5 16.1,28.4 15.6,32.5 17.4,38.3 21.5,40.6 27.7,40.6 33.5,36.2   36.1,29.3 30.1,22 28.4,17.9 "/>
  //     <path fill="#263238" d="M34.3,23.9c-1.6-2.3-2.9-3.7-3.6-6.6c-0.7-2.9,0.2-2.1-0.4-4.6c-0.3-1.3-0.8-2.2-1.3-2.9  c-0.6-0.7-1.3-1.1-1.7-1.2c-0.9-0.5-3-1.3-5.6,0.1c-2.7,1.4-2.4,4.4-1.9,10.5c0,0.4-0.1,0.9-0.3,1.3c-0.4,0.9-1.1,1.7-1.7,2.4  c-0.7,1-1.4,2-1.9,3.1c-1.2,2.3-2.3,5.2-2,6.3c0.5-0.1,6.8,9.5,6.8,9.7c0.4-0.1,2.1-0.1,3.6-0.1c2.1-0.1,3.3-0.2,5,0.2  c0-0.3-0.1-0.6-0.1-0.9c0-0.6,0.1-1.1,0.2-1.8c0.1-0.5,0.2-1,0.3-1.6c-1,0.9-2.8,1.9-4.5,2.2c-1.5,0.3-4-0.2-5.2-1.7  c0.1,0,0.3,0,0.4-0.1c0.3-0.1,0.6-0.2,0.7-0.4c0.3-0.5,0.1-1-0.1-1.3c-0.2-0.3-1.7-1.4-2.4-2c-0.7-0.6-1.1-0.9-1.5-1.3  c0,0-0.6-0.6-0.8-0.8c-0.2-0.2-0.3-0.4-0.4-0.5c-0.2-0.5-0.3-1.1-0.2-1.9c0.1-1.1,0.5-2,1-3c0.2-0.4,0.7-1.2,0.7-1.2  s-1.7,4.2-0.8,5.5c0,0,0.1-1.3,0.5-2.6c0.3-0.9,0.8-2.2,1.4-2.9s2.1-3.3,2.2-4.9c0-0.7,0.1-1.4,0.1-1.9c-0.4-0.4,6.6-1.4,7-0.3  c0.1,0.4,1.5,4,2.3,5.9c0.4,0.9,0.9,1.7,1.2,2.7c0.3,1.1,0.5,2.6,0.5,4.1c0,0.3,0,0.8-0.1,1.3c0.2,0,4.1-4.2-0.5-7.7  c0,0,2.8,1.3,2.9,3.9c0.1,2.1-0.8,3.8-1,4.1c0.1,0,2.1,0.9,2.2,0.9c0.4,0,1.2-0.3,1.2-0.3c0.1-0.3,0.4-1.1,0.4-1.4  C37.6,29.9,35.9,26.2,34.3,23.9z"/>
  //     <g>
  //         <ellipse fill="#ECEFF1" cx="21.6" cy="15.3" rx="1.3" ry="2"/>
  //         <ellipse fill="#ECEFF1" cx="26.1" cy="15.2" rx="1.7" ry="2.3"/>
  //     </g>
  //     <g>

  //             <ellipse transform="matrix(-0.1254 -0.9921 0.9921 -0.1254 8.9754 38.9969)" fill="#212121" cx="21.7" cy="15.5" rx="1.2" ry="0.7"/>
  //         <ellipse fill="#212121" cx="26" cy="15.6" rx="1" ry="1.3"/>
  //     </g>
  //     <g>
  //         <path fill="#FFC107" d="M39.3,37.6c-0.4-0.2-1.1-0.5-1.7-1.4c-0.3-0.5-0.2-1.9-0.7-2.5c-0.3-0.4-0.7-0.2-0.8-0.2   c-0.9,0.2-3,1.6-4.4,0c-0.2-0.2-0.5-0.5-1-0.5c-0.5,0-0.7,0.2-0.9,0.6s-0.2,0.7-0.2,1.7c0,0.8,0,1.7-0.1,2.4   c-0.2,1.7-0.5,2.7-0.5,3.7c0,1.1,0.3,1.8,0.7,2.1c0.3,0.3,0.8,0.5,1.9,0.5c1.1,0,1.8-0.4,2.5-1.1c0.5-0.5,0.9-0.7,2.3-1.7   c1.1-0.7,2.8-1.6,3.1-1.9c0.2-0.2,0.5-0.3,0.5-0.9C40,37.9,39.6,37.7,39.3,37.6z"/>
  //         <path fill="#FFC107" d="M19.2,37.9c-1-1.6-1.1-1.9-1.8-2.9c-0.6-1-1.9-2.9-2.7-2.9c-0.6,0-0.9,0.3-1.3,0.7   c-0.4,0.4-0.8,1.3-1.5,1.8c-0.6,0.5-2.3,0.4-2.7,1c-0.4,0.6,0.4,1.5,0.4,3c0,0.6-0.5,1-0.6,1.4c-0.1,0.5-0.2,0.8,0,1.2   c0.4,0.6,0.9,0.8,4.3,1.5c1.8,0.4,3.5,1.4,4.6,1.5c1.1,0.1,3,0,3-2.7C21,39.9,20.1,39.5,19.2,37.9z"/>
  //         <path fill="#FFC107" d="M21.1,19.8C20.5,19.4,20,19,20,18.4c0-0.6,0.4-0.8,1-1.3c0.1-0.1,1.2-1.1,2.3-1.1s2.4,0.7,2.9,0.9   c0.9,0.2,1.8,0.4,1.7,1.1c-0.1,1-0.2,1.2-1.2,1.7c-0.7,0.2-2,1.3-2.9,1.3c-0.4,0-1,0-1.4-0.1C22.1,20.8,21.6,20.3,21.1,19.8z"/>
  //     </g>
  //     <g>
  //         <path fill="#634703" d="M20.9,19c0.2,0.2,0.5,0.4,0.8,0.5c0.2,0.1,0.5,0.2,0.5,0.2c0.4,0,0.7,0,0.9,0c0.5,0,1.2-0.2,1.9-0.6   c0.7-0.3,0.8-0.5,1.3-0.7c0.5-0.3,1-0.6,0.8-0.7c-0.2-0.1-0.4,0-1.1,0.4c-0.6,0.4-1.1,0.6-1.7,0.9c-0.3,0.1-0.7,0.3-1,0.3   c-0.3,0-0.6,0-0.9,0c-0.3,0-0.5-0.1-0.8-0.2c-0.2-0.1-0.3-0.2-0.4-0.2c-0.2-0.1-0.6-0.5-0.8-0.6c0,0-0.2,0-0.1,0.1   C20.6,18.7,20.7,18.8,20.9,19z"/>
  //         <path fill="#634703" d="M23.9,16.8c0.1,0.2,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.1,0.2,0.1c0.1-0.1,0-0.3-0.1-0.3   C24.4,16.7,23.9,16.7,23.9,16.8z"/>
  //         <path fill="#634703" d="M22.3,17c0,0.1,0.2,0.2,0.2,0.1c0.1-0.1,0.2-0.2,0.3-0.2c0.2-0.1,0.1-0.2-0.2-0.2   C22.4,16.8,22.4,16.9,22.3,17z"/>
  //     </g>
  //     <path fill="#455A64" d="M32,34.7c0,0.1,0,0.2,0,0.3c0.2,0.4,0.7,0.5,1.1,0.5c0.6,0,1.2-0.4,1.5-0.8c0-0.1,0.1-0.2,0.2-0.3  c0.2-0.3,0.3-0.5,0.4-0.6c0,0-0.1-0.1-0.1-0.2c-0.1-0.2-0.4-0.4-0.8-0.5c-0.3-0.1-0.8-0.2-1-0.2c-0.9-0.1-1.4,0.2-1.7,0.5  c0,0,0.1,0,0.1,0.1c0.2,0.2,0.3,0.4,0.3,0.7C32.1,34.4,32,34.5,32,34.7z"/>
  //     </svg>`
  // }
];

const InitialActivity = ({ isEditScreen = false }) => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [currentForm, setCurrentForm] = useState(1); // 1,2,3

  const [formData, setFormData] = useState({
    logoBackgroundColor: "#FFFFFF",
    splashBackgroundColor: "#FFFFFF",
  });
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);

  const [initialImages, setInitialImages] = useState({});
  const [currentProjectData, setCurrentProjectData] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isLogoImgLoading, setIsLogoImgLoading] = useState(true);
  const [isSplashImgLoading, setIsSplashImgLoading] = useState(true);

  useEffect(() => {
    (async () => {
      if (isEditScreen) {
        const response = await getProjectData(id);
        const {
          platform,
          websiteUrl,
          appName,
          logoBackgroundColor = "#ffffff",
          splashBackgroundColor = "#ffffff",
          applicationId,
        } = response.data?.projectData || {};

        setCurrentProjectData(response.data?.projectData || {});

        setInitialImages({
          logoImageDownloadLink:
            response.data?.projectData?.logoImageDownloadLink,
          splashImageDownloadLink:
            response.data?.projectData?.splashImageDownloadLink,
        });

        setFormData({
          websiteUrl,
          appName,
          logoBackgroundColor,
          splashBackgroundColor,
          applicationId,
        });
        // TODO: HACk - when we get real arrays of platforms in future, replace code everywhere
        setSelectedPlatforms([platform]);
      } else {
        setSelectedPlatforms(["android"]);
      }

      setIsLoading(false);
    })();
  }, []);

  const getFormAttributes = (id) => {
    return {
      id,
      value: formData[id],
    };
  };

  const handlePlatformCardClick = (id) => {
    const index = selectedPlatforms.findIndex(
      (platformId) => platformId === id
    );
    console.log("heree", index, id, selectedPlatforms);
    if (index === -1) {
      setSelectedPlatforms([...selectedPlatforms, id]);
    } else {
      setSelectedPlatforms(
        selectedPlatforms.filter((platformId) => platformId !== id)
      );
    }
  };

  const restoreOriginalImage = (name) => {
    const imageName =
      name === "logoImage"
        ? "logoImageDownloadLink"
        : "splashImageDownloadLink";

    setInitialImages({
      ...initialImages,
      [imageName]: currentProjectData.imagesLink?.[imageName],
    });
  };

  const handleRemoveImage = (name) => {
    const imageName =
      name === "logoImage"
        ? "logoImageDownloadLink"
        : "splashImageDownloadLink";

    if (!formData[name] && initialImages?.[imageName]) {
      setInitialImages({ ...initialImages, [imageName]: null });
    }

    setFormData({ ...formData, [name]: null });
  };

  const handleFormChange = (ev) => {
    const target = ev.target;
    let value = target.value;

    if (target.type === "checkbox") {
      value = target.checked;
    } else if (target.type === "file") {
      value = target.files[0];
    }
    setFormData({ ...formData, [target.id]: value });
  };

  const handleCancel = () => {
    if (isEditScreen) {
      return navigate(`/project/${id}`);
    }
    return navigate("/projects");
  };

  const handleSaveChanges = async () => {
    const requestData = {
      ...formData,
      platform: [selectedPlatforms[0]],
    };
    setIsSaving(true);

    console.log("Save changes ", requestData);

    if (!requestData["appName"]) {
      alert("Please specify your app's name");
      return;
    } else if (!isValidAppName(requestData["appName"])) {
      alert(
        "App name must have only alphanumeric characters, space, _, . and length should be more than 1 and less than 30"
      );
      return;
    } else if (!requestData["websiteUrl"]) {
      alert("Please specify your website's URL");
      return;
    } else if (!isValidHttpUrl(requestData["websiteUrl"])) {
      alert("Please provide valid website URL");
      return;
    } else if (!isValidApplicationId(requestData["applicationId"])) {
      alert(
        "Application ID must have only alphanumeric characters, _, . and length should be more than 6"
      );
      return;
    } else if (!requestData["platform"]) {
      alert("Please choose a platform");
      return;
    } else if (
      !requestData["logoImage"] &&
      !initialImages["logoImageDownloadLink"]
    ) {
      // logo image is not uploaded
      alert("Please upload logo image");
      return;
    } else if (!requestData["logoBackgroundColor"]) {
      alert("Please specify Logo background color");
      return;
    } else if (
      !requestData["splashImage"] &&
      !initialImages["splashImageDownloadLink"]
    ) {
      // splash image is not uploaded
      alert("Please upload splash image");
      return;
    } else if (!requestData["splashBackgroundColor"]) {
      alert("Please specify Splash screen background color");
      return;
    }

    try {
      let projectId = id;
      if (isEditScreen) {
        await updateProject({ ...requestData, projectId: id });
      } else {
        const { data: { projectData = {} } = {} } = await createProject(
          requestData
        );
        projectId = projectData.projectId;
      }
      navigate(`/project/${projectId}`);
    } catch (e) {
      console.error(e);
      alert("Something went wrong, Please try again or contact support.");
    } finally {
      setIsSaving(false);
    }
  };

  // fill application id when appname is filed the first time with com.${appName} after focus goes out of appname field
  const setAppName = (ev) => {
    let appName = ev.target.value;
    let transformedName = appName.replace(/\s/g, ".");

    if (appName && !formData.applicationId) {
      setFormData({ ...formData, applicationId: `com.${transformedName}.app` });
    }
  };

  if (isEditScreen && isLoading) {
    return <Loader size="lg" />;
  }

  return (
    <div className="px-40 py-5">
      <div className="my-4">
        <form onChange={handleFormChange}>
          {/* Step 1 */}
          {(currentForm === 1 || isEditScreen) && (
            <>
              <div className="mb-6">
                <label
                  htmlFor="appName"
                  className="block mb-2 text-sm font-medium text-gray-900 dark-to-replace:text-white"
                >
                  App name*
                </label>
                <Input
                  placeholder="App name"
                  {...getFormAttributes("appName")}
                  required
                  onBlur={setAppName}
                />
              </div>
              <div className="mb-6">
                <label
                  htmlFor="websiteUrl"
                  className="block mb-2 text-sm font-medium text-gray-900 dark-to-replace:text-white"
                >
                  Website URL*
                </label>
                <Input
                  placeholder="https://yourwebsiteurl.com"
                  {...getFormAttributes("websiteUrl")}
                  required
                />
              </div>
              <div className="mb-6">
                <label
                  htmlFor="applicationId"
                  className="block mb-2 text-sm font-medium text-gray-900 dark-to-replace:text-white"
                >
                  Application ID*
                </label>
                <Input
                  placeholder="com.example.app"
                  {...getFormAttributes("applicationId")}
                  required
                />
              </div>

              <div className="mb-6">
                <label
                  htmlFor="logoImage"
                  className="block mb-2 text-sm font-medium text-gray-900 dark-to-replace:text-white w-fit"
                >
                  <span>Logo</span>
                  <Tooltip
                    tooltipText="Click to read more about logo design"
                    className="ml-1 hint--right"
                  >
                    <a
                      className="text-blue-600 text-xs"
                      href="https://usecarveapp.com/blog/5-logo-sizes-in-apps/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      (Learn more)
                    </a>
                  </Tooltip>
                </label>
                {formData["logoImage"] ||
                initialImages?.logoImageDownloadLink ? (
                  <div className="max-w-md h-auto inline-block relative">
                    {isLogoImgLoading && (
                      <div className="loader animate-pulse w-96 h-96">
                        <div className="absolute top-0 left-0 w-full h-full bg-slate-200 rounded-lg"></div>
                      </div>
                    )}
                    <img
                      className="h-auto rounded-lg border pointer-events-none select-none"
                      src={
                        (formData["logoImage"] &&
                          URL.createObjectURL(formData["logoImage"])) ||
                        initialImages?.logoImageDownloadLink
                      }
                      onLoad={() => setIsLogoImgLoading(false)}
                      alt="logoImage"
                    />
                    <div
                      onClick={() => handleRemoveImage("logoImage")}
                      className="cursor-pointer absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-slate-400 border-2 border-white rounded-full -top-2 -right-2 dark-to-replace:border-gray-900"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-4 h-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </div>
                  </div>
                ) : (
                  <>
                    <FileUpload id="logoImage" accept=".png, .jpg, .jpeg" />
                    {currentProjectData?.imagesLink?.logoImageDownloadLink && (
                      <Button
                        type="secondary"
                        className="mt-2"
                        onClick={() => restoreOriginalImage("logoImage")}
                      >
                        Keep current logo image
                      </Button>
                    )}
                  </>
                )}
              </div>

              <div className="mb-6">
                <label
                  htmlFor="logoBackgroundColor"
                  className="block mb-2 text-sm font-medium text-gray-900 dark-to-replace:text-white"
                >
                  Logo background color*
                </label>
                <Input
                  className="w-24"
                  {...getFormAttributes("logoBackgroundColor")}
                  required
                  type="color"
                />
              </div>

              <div className="mb-6">
                <label
                  htmlFor="splashImage"
                  className="block mb-2 text-sm font-medium text-gray-900 dark-to-replace:text-white w-fit"
                >
                  Splash screen
                  <Tooltip
                    tooltipText="Click to read more about splash screen design"
                    className="ml-1 hint--right"
                  >
                    <a
                      className="text-blue-600 text-xs"
                      href="https://usecarveapp.com/blog/6-splash-screen-design/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      (Learn more)
                    </a>
                  </Tooltip>
                </label>
                {formData["splashImage"] ||
                initialImages?.splashImageDownloadLink ? (
                  <div className="max-w-md h-auto inline-block relative">
                    {isSplashImgLoading && (
                      <div className="loader animate-pulse w-96 h-96">
                        <div className="absolute top-0 left-0 w-full h-full bg-slate-200 rounded-lg"></div>
                      </div>
                    )}
                    <img
                      className="h-auto rounded-lg border pointer-events-none select-none"
                      src={
                        formData["splashImage"]
                          ? URL.createObjectURL(formData["splashImage"])
                          : initialImages?.splashImageDownloadLink
                      }
                      alt="splashImage"
                      onLoad={() => setIsSplashImgLoading(false)}
                    />
                    <div
                      onClick={() => handleRemoveImage("splashImage")}
                      className="cursor-pointer absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-slate-400 border-2 border-white rounded-full -top-2 -right-2 dark-to-replace:border-gray-900"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-4 h-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </div>
                  </div>
                ) : (
                  <>
                    <FileUpload id="splashImage" accept=".png, .jpg, .jpeg" />
                    {currentProjectData?.imagesLink
                      ?.splashImageDownloadLink && (
                      <Button
                        type="secondary"
                        className="mt-2"
                        onClick={() => restoreOriginalImage("splashImage")}
                      >
                        Keep current splash image
                      </Button>
                    )}
                  </>
                )}
              </div>

              <div className="mb-6">
                <label
                  htmlFor="splashBackgroundColor"
                  className="block mb-2 text-sm font-medium text-gray-900 dark-to-replace:text-white"
                >
                  Splash screen background color*
                </label>
                <Input
                  className="w-24"
                  {...getFormAttributes("splashBackgroundColor")}
                  required
                  type="color"
                />
              </div>
            </>
          )}

          {/* Step 2 */}
          <>
            <label className="block mb-2 text-sm font-medium text-gray-900 dark-to-replace:text-white">
              Phone Platforms*
            </label>

            <div className="flex gap-10 flex-wrap">
              {platforms.slice(0, 2).map((platform) => (
                <div
                  onClick={() =>
                    !platform.disabled && handlePlatformCardClick(platform.id)
                  }
                  key={platform.name}
                  className={
                    platform.disabled
                      ? "bg-gray-300 pointer-events-none opacity-50 text-center p-6 relative rounded-lg"
                      : `${addClass(
                          "relative ring-blue-400 ring-2",
                          selectedPlatforms.includes(platform.id)
                        )} cursor-pointer p-6 bg-white border border-gray-200 rounded-lg shadow w-fit hover:bg-gray-100 dark-to-replace:border-gray-700 dark-to-replace:bg-gray-800 dark-to-replace:hover:bg-gray-700`
                  }
                >
                  {/* Coming soon message */}
                  {platform.disabled && (
                    <span className="select-none absolute right-[30%] bottom-[50%] transform bg-red-500 px-2 py-1 text-white">
                      Coming soon
                    </span>
                  )}

                  {/* content of platform box */}
                  <div
                    dangerouslySetInnerHTML={{ __html: platform.icon }}
                  ></div>
                  <div className="mt-5 text-lg text-center font-bold text-gray-700 dark-to-replace:text-white">
                    {platform.name}
                  </div>
                  {selectedPlatforms.includes(platform.id) && (
                    <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-blue-500 border-2 border-white rounded-full -top-2 -right-2 dark-to-replace:border-gray-900">
                      <svg
                        aria-hidden="true"
                        className="w-3 h-3"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="mt-6 text-xs text-neutral-500">
              Did you know? Before submitting an app which is using a website to
              the Play Store, there are important compliance requirements you
              need to follow. Check out the full list{" "}
              <a
                href="https://usecarveapp.com/blog/7-app-guidelines-for-webview/"
                target="_blank"
                rel="noreferrer"
                className="underline text-blue-600"
              >
                here
              </a>
              .
            </div>
          </>
        </form>
      </div>

      <Navigation
        currentForm={currentForm}
        setCurrentForm={setCurrentForm}
        onSaveChanges={handleSaveChanges}
        isEditScreen={isEditScreen}
        onCancel={handleCancel}
        isSaving={isSaving}
      />
    </div>
  );
};

export default InitialActivity;
