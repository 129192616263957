import React, { useEffect, useState } from "react";
import { getRelativeTime } from "../../utils/date-helper";
import { getUsageStats } from "../../api/payment";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";

const TIME_PERIODS = [
  {
    key: "this_month",
    label: "This Month",
    timePeriods: {
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      ).getTime(),
      endDate: new Date().getTime() + 24 * 60 * 60 * 1000,
    },
  },
  {
    key: "last_month",
    label: "Last Month",
    timePeriods: {
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 1,
        1
      ).getTime(),
      endDate:
        new Date(new Date().getFullYear(), new Date().getMonth(), 0).getTime() +
        24 * 60 * 60 * 1000,
    },
  },
  {
    key: "this_year",
    label: "This year",
    timePeriods: {
      startDate: new Date(new Date().getFullYear(), 0, 1).getTime(),
      endDate: new Date().getTime() + 24 * 60 * 60 * 1000,
    },
  },
  {
    key: "all_time",
    label: "All time",
    timePeriods: {
      startDate: 0,
      endDate: new Date().getTime() + 24 * 60 * 60 * 1000,
    },
  },
];

function Settings() {
  const [usageData, setUsageData] = useState(null);
  const [timePeriod, setTimePeriod] = useState(TIME_PERIODS[0].key);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const value = TIME_PERIODS.find(
          (data) => data.key === timePeriod
        )?.timePeriods;

        const { data } = await getUsageStats(value.startDate, value.endDate);
        setUsageData(data);
      } catch (err) {
        // alert("Something went wrong, Please reload.");
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [timePeriod]);

  return (
    <div className="pl-40 pr-20 flex flex-col gap-4 mt-4 text-gray-900">
      <div className="pb-4 flex items-center gap-2">
        <div className="w-9 h-9 rounded-full bg-blue-100 p-[7px]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="#1d4ed8"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 3v11.25A2.25 2.25 0 0 0 6 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0 1 18 16.5h-2.25m-7.5 0h7.5m-7.5 0-1 3m8.5-3 1 3m0 0 .5 1.5m-.5-1.5h-9.5m0 0-.5 1.5m.75-9 3-3 2.148 2.148A12.061 12.061 0 0 1 16.5 7.605"
            />
          </svg>
        </div>
        <h1 className="text-2xl font-semibold">Usage Info</h1>
      </div>

      {usageData &&
        (!usageData?.remainingBuilds ||
          usageData?.remainingBuilds < 5 ||
          !usageData?.remainingProjects ||
          usageData?.remainingProjects < 2) && (
          <div className="text-center">
            <h3 className="text-xl text-blue-900 dark-to-replace:text-white">
              {!usageData?.remainingBuilds || usageData?.remainingBuilds < 5
                ? "Currently you seem low on available builds"
                : (!usageData?.remainingProjects ||
                    usageData?.remainingProjects < 2) &&
                  "Currently you seem low on available projects"}
            </h3>

            <Link to="/pricing">
              <button
                className="mt-4 bg-green-600 hover:bg-green-700 px-4 py-2 text-white uppercase rounded text-sm tracking-wider"
                type="submit"
              >
                Buy a pack
              </button>
            </Link>
          </div>
        )}

      <div className="px-4 py-4 mb-5 bg-white dark-to-replace:bg-gray-900 border-b shadow-sm sm:rounded-lg">
        <h2 className="text-xl font-medium mb-4">Projects</h2>
        <div className="flex justify-between items-center w-60">
          <h5 className="font-medium text-gray-800">Remaining projects:</h5>{" "}
          {isLoading && !usageData ? (
            <span className="h-2.5 animate-pulse bg-gray-200 rounded-full dark-to-replace:bg-gray-700 w-20"></span>
          ) : (
            <span>{usageData.remainingProjects} projects</span>
          )}
        </div>
      </div>
      <div className="px-4 py-4 mb-5 bg-white dark-to-replace:bg-gray-900 border-b shadow-sm sm:rounded-lg">
        <h2 className="text-xl font-medium mb-4">Builds</h2>
        <div className="flex justify-between items-center w-60">
          <h5 className="font-medium text-gray-800">Remaining builds:</h5>{" "}
          {isLoading && !usageData ? (
            <span className="h-2.5 animate-pulse bg-gray-200 rounded-full dark-to-replace:bg-gray-700 w-20"></span>
          ) : (
            <span>{usageData.remainingBuilds} builds</span>
          )}
        </div>
        <div className="flex justify-between items-center w-60 mt-4">
          <h5 className="font-medium text-gray-800">In-Progress builds:</h5>{" "}
          {isLoading && !usageData ? (
            <span className="h-2.5 animate-pulse bg-gray-200 rounded-full dark-to-replace:bg-gray-700 w-20"></span>
          ) : (
            <span>{usageData.inProgressBuilds} builds</span>
          )}
        </div>
        <div>
          <div className="flex justify-between items-center my-4">
            <h5 className="font-medium">Successful build history</h5>
            <select
              id="period-filter"
              className="w-32 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark-to-replace:bg-gray-700 dark-to-replace:border-gray-600 dark-to-replace:placeholder-gray-400 dark-to-replace:text-white dark-to-replace:focus:ring-blue-500 dark-to-replace:focus:border-blue-500"
              value={timePeriod}
              onChange={(event) => {
                setTimePeriod(event.target.value);
              }}
            >
              {TIME_PERIODS.map(({ key, label }) => (
                <option key={key} value={key}>
                  {label}
                </option>
              ))}
            </select>
          </div>
          <table className="w-full text-sm text-left text-gray-500 dark-to-replace:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-indigo-100 dark-to-replace:bg-gray-700 dark-to-replace:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Project
                </th>
                <th scope="col" className="px-6 py-3">
                  Build
                </th>
                <th scope="col" className="px-6 py-3">
                  Platform
                </th>
                <th scope="col" className="px-6 py-3">
                  Built at
                </th>
              </tr>
            </thead>
            <tbody>
              {usageData?.allBuildsForPeriod?.map((project) => (
                //   {
                //     "id": "5Yzsxl4YN6sNtUbNnwxw",
                //     "buildStatus": "SUCCESS",
                //     "projectId": "MIBvm0pEgu4U027sEvDs",
                //     "platform": "Android"
                // }
                <tr
                  key={project.id}
                  onClick={() => navigate(`/project/${project.projectId}`)}
                  className="bg-white border-b cursor-pointer dark-to-replace:bg-gray-800 dark-to-replace:border-gray-700 hover:bg-blue-50 dark-to-replace:hover:bg-gray-600"
                >
                  <td>
                    <div className="pl-3">
                      <div className=" font-semibold">
                        {project.projectName}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="pl-3">
                      <div className=" font-semibold">{project.id}</div>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex items-center">
                      <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark-to-replace:bg-blue-900 dark-to-replace:text-blue-300">
                        {project.platform}
                      </span>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    {getRelativeTime(project.createdAt) || "None"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {isLoading ? (
            <div className="w-full h-40">
              <Loader size="md" />
            </div>
          ) : (
            !usageData?.allBuildsForPeriod?.length && (
              <div className="w-full h-32">
                <h5 className="w-fit mx-auto mt-8">No data present</h5>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
}

export default Settings;
