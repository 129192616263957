import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LoginImage from "../../assets/login_img_app.svg";

import { googleLoginUser, loginUser, registerUser } from "../../api/auth";
import {
  authenticateUser,
  isValidEmail,
  isValidPassword,
} from "../../utils/auth-helper";

const LoginFormComponent = ({ isEmailVerified, verifiedEmail }) => {
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    name: "",
  });
  const [errors, setErrors] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);

  const isRegister = location.pathname === "/register" ? true : false;

  const handleLoginForm = async (evt) => {
    evt.preventDefault();

    const errors = validateCredentials(credentials);

    setErrors(() => ({ ...errors }));

    try {
      setIsSaving(true);
      // if no errors then proceed to make request
      if (!Object.keys(errors).length) {
        if (isRegister) {
          await registerUser({
            email: credentials.email,
            password: credentials.password,
            name: credentials.name,
          });
        } else {
          const responseData = await authenticateUser(credentials);
          if (responseData.status) {
            navigate("/projects");
          }
        }
      }
    } catch (e) {
      setErrors({
        global: e.response?.data?.message,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const validateCredentials = (credentials) => {
    let errors = {};

    if (credentials.email === "") {
      errors = Object.assign(errors, {
        email: "This field is required",
      });
    }

    if (!isValidEmail(credentials.email)) {
      errors = Object.assign(errors, {
        email: "Invalid email address",
      });
    }

    if (credentials.password === "") {
      errors = Object.assign(errors, {
        password: "This field is required",
      });
    }

    if (isRegister) {
      if (credentials.password?.length < 8) {
        errors = Object.assign(errors, {
          password: "Password should be at least 7 characters",
        });
      }

      if (!isValidPassword(credentials.password)) {
        errors = Object.assign(errors, {
          password:
            "Password should have atleast a number, a letter and a special character",
        });
      }
    }

    if (credentials.name === "" && isRegister) {
      errors = Object.assign(errors, {
        name: "This field is required",
      });
    }

    return errors;
  };

  const handleInputChange = (evt) => {
    evt.persist();
    setCredentials((credentials) => ({
      ...credentials,
      [evt.target.name]: evt.target.value,
    }));
  };

  const googleLogin = () => {
    googleLoginUser();
  };

  return (
    <section className="h-screen flex flex-col md:flex-row justify-center space-y-10 md:space-y-0 md:space-x-16 items-center my-2 mx-5 md:mx-0 md:my-0">
      <div className="md:w-1/3 max-w-sm">
        <img
          src={LoginImage}
          alt="LoginImage"
          className="pointer-events-none select-none"
        />
      </div>
      <div className="md:w-1/3 max-w-sm">
        {/* <>
          <div className="text-center md:text-left">
            <div className="flex justify-center">
              <button
                onClick={googleLogin}
                className="flex items-center bg-white dark-to-replace:bg-gray-900 border border-gray-300 rounded-lg shadow-md px-6 py-2 text-sm font-medium text-gray-800 dark-to-replace:text-white hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              >
                <svg
                  className="h-6 w-6 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="800px"
                  height="800px"
                  viewBox="-0.5 0 48 48"
                  version="1.1"
                >
                  <title>Google-color</title>
                  <desc>Created with Sketch.</desc>
                  <g
                    id="Icons"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <g
                      id="Color-"
                      transform="translate(-401.000000, -860.000000)"
                    >
                      <g
                        id="Google"
                        transform="translate(401.000000, 860.000000)"
                      >
                        <path
                          d="M9.82727273,24 C9.82727273,22.4757333 10.0804318,21.0144 10.5322727,19.6437333 L2.62345455,13.6042667 C1.08206818,16.7338667 0.213636364,20.2602667 0.213636364,24 C0.213636364,27.7365333 1.081,31.2608 2.62025,34.3882667 L10.5247955,28.3370667 C10.0772273,26.9728 9.82727273,25.5168 9.82727273,24"
                          id="Fill-1"
                          fill="#FBBC05"
                        ></path>
                        <path
                          d="M23.7136364,10.1333333 C27.025,10.1333333 30.0159091,11.3066667 32.3659091,13.2266667 L39.2022727,6.4 C35.0363636,2.77333333 29.6954545,0.533333333 23.7136364,0.533333333 C14.4268636,0.533333333 6.44540909,5.84426667 2.62345455,13.6042667 L10.5322727,19.6437333 C12.3545909,14.112 17.5491591,10.1333333 23.7136364,10.1333333"
                          id="Fill-2"
                          fill="#EB4335"
                        ></path>
                        <path
                          d="M23.7136364,37.8666667 C17.5491591,37.8666667 12.3545909,33.888 10.5322727,28.3562667 L2.62345455,34.3946667 C6.44540909,42.1557333 14.4268636,47.4666667 23.7136364,47.4666667 C29.4455,47.4666667 34.9177955,45.4314667 39.0249545,41.6181333 L31.5177727,35.8144 C29.3995682,37.1488 26.7323182,37.8666667 23.7136364,37.8666667"
                          id="Fill-3"
                          fill="#34A853"
                        ></path>
                        <path
                          d="M46.1454545,24 C46.1454545,22.6133333 45.9318182,21.12 45.6113636,19.7333333 L23.7136364,19.7333333 L23.7136364,28.8 L36.3181818,28.8 C35.6879545,31.8912 33.9724545,34.2677333 31.5177727,35.8144 L39.0249545,41.6181333 C43.3393409,37.6138667 46.1454545,31.6490667 46.1454545,24"
                          id="Fill-4"
                          fill="#4285F4"
                        ></path>
                      </g>
                    </g>
                  </g>
                </svg>
                <span>Continue with Google</span>
              </button>
            </div>
          </div>
          <div className="my-5 flex items-center before:mt-0.5 before:flex-1 before:border-t before:border-neutral-300 after:mt-0.5 after:flex-1 after:border-t after:border-neutral-300">
            <p className="mx-4 mb-0 text-center font-semibold text-slate-500">
              Or
            </p>
          </div>
        </> */}
        <form onSubmit={handleLoginForm.bind(this)}>
          {isEmailVerified === true && (
            <div
              id="alert-2"
              className="flex items-center p-4 mb-4 text-green-800 rounded-lg bg-green-50 dark-to-replace:bg-gray-800 dark-to-replace:text-green-400"
              role="alert"
            >
              <svg
                className="flex-shrink-0 w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
              </svg>
              <span className="sr-only">Info</span>
              <div className="ms-3 text-sm font-medium">
                Your email {verifiedEmail} is verified. You can login now.
              </div>
            </div>
          )}
          {isEmailVerified === false && (
            <div
              id="alert-2"
              className="flex items-center p-4 mb-4 text-red-800 rounded-lg bg-red-50 dark-to-replace:bg-gray-800 dark-to-replace:text-red-400"
              role="alert"
            >
              <svg
                className="flex-shrink-0 w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
              </svg>
              <span className="sr-only">Info</span>
              <div className="ms-3 text-sm font-medium">
                Email verification failed for {verifiedEmail}. Try again or
                contact support@usecarveapp.com
              </div>
            </div>
          )}
          {errors.global && (
            <div
              id="alert-2"
              className="flex items-center p-4 mb-4 text-red-800 rounded-lg bg-red-50 dark-to-replace:bg-gray-800 dark-to-replace:text-red-400"
              role="alert"
            >
              <svg
                className="flex-shrink-0 w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
              </svg>
              <span className="sr-only">Info</span>
              <div className="ms-3 text-sm font-medium">{errors.global}</div>
            </div>
          )}
          {isRegister && (
            <section className="mb-4">
              <input
                className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded"
                type="text"
                id="name"
                name="name"
                placeholder="Full Name"
                value={credentials.name}
                onChange={handleInputChange.bind(this)}
              />

              {errors.hasOwnProperty("name") && (
                <p className="text-red-500 text-xs italic mt-1">
                  {errors.name}
                </p>
              )}
            </section>
          )}
          <section>
            <input
              className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded"
              type="text"
              id="email"
              name="email"
              placeholder="Email Address"
              value={credentials.email}
              onChange={handleInputChange.bind(this)}
            />

            {errors.hasOwnProperty("email") && (
              <p className="text-red-500 text-xs italic mt-1">{errors.email}</p>
            )}
          </section>
          <section>
            <input
              className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded mt-4"
              type="password"
              id="password"
              name="password"
              placeholder="Password"
              value={credentials.password}
              onChange={handleInputChange.bind(this)}
            />

            {errors.hasOwnProperty("password") && (
              <p className="text-red-500 text-xs italic mt-1">
                {errors.password}
              </p>
            )}
          </section>
          {!isRegister && (
            <div className="mt-4 flex justify-between font-semibold text-sm">
              <label className="flex text-slate-500 hover:text-slate-600 cursor-pointer">
                <input className="mr-1" type="checkbox" />
                <span>Remember Me</span>
              </label>
              <Link
                className="text-blue-600 hover:text-blue-700 hover:underline hover:underline-offset-4"
                to="/forgot-password"
              >
                Forgot Password?
              </Link>
            </div>
          )}
          <div className="text-center md:text-left">
            <button
              disabled={isSaving}
              className="mt-4 bg-blue-600 hover:bg-blue-700 px-4 py-2 text-white uppercase rounded text-xs tracking-wider disabled:opacity-50 disabled:cursor-not-allowed"
              type="submit"
            >
              {isRegister ? "Register" : "Login"}
            </button>
          </div>
        </form>
        {isRegister ? (
          <div className="mt-4 font-semibold text-sm text-slate-500 text-center md:text-left">
            Have an account?{" "}
            <Link
              className="text-blue-600 hover:underline hover:underline-offset-4"
              to="/login"
            >
              Login
            </Link>
          </div>
        ) : (
          <div className="mt-4 font-semibold text-sm text-slate-500 text-center md:text-left">
            Don't have an account?{" "}
            <Link
              className="text-red-600 hover:underline hover:underline-offset-4"
              to="/register"
            >
              Register
            </Link>
          </div>
        )}
      </div>
    </section>
  );
};

export default LoginFormComponent;
