import React, { useState } from "react";
import { isValidEmail, isValidPassword } from "../../utils/auth-helper";
import { Link, useSearchParams } from "react-router-dom";
import { forgotPassword, resetPassword } from "../../api/auth";

const ResetPasswordPage = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [emailSent, setEmailSent] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [searchParams] = useSearchParams();

  const handleResetPasswordForm = async (evt) => {
    evt.preventDefault();

    const currentErrors = validateCredentials(password);
    setErrors(() => ({ ...currentErrors }));

    try {
      setIsSending(true);
      // if no errors then proceed to make request
      if (!Object.keys(currentErrors).length) {
        // Reset pass api call
        await resetPassword(searchParams.get("token"), { password });
        setEmailSent(true);
      }
    } catch (e) {
      setErrors({
        global: e.response?.data?.message,
      });
    } finally {
      setIsSending(false);
    }
  };

  const validateCredentials = (password) => {
    let errors = {};

    if (!isValidPassword(password)) {
      errors = Object.assign(errors, {
        password:
          "Password should have atleast a number, a letter and a special character",
      });
    }

    if (password?.length < 8) {
      errors = Object.assign(errors, {
        password: "Password should be at least 8 characters",
      });
    }

    if (password !== confirmPassword) {
      errors = Object.assign(errors, {
        confirmPassword: "Password does not match",
      });
    }

    return errors;
  };

  return (
    <main className="login-page">
      <div className="backdrop">
        {emailSent ? (
          <section className="h-screen flex flex-col md:flex-row justify-center space-y-10 md:space-y-0 md:space-x-16 items-center my-2 mx-5 md:mx-0 md:my-0">
            <div className="p-8 rounded-md bg-white w-fit h-fit flex flex-col text-center justify-center">
              <div className="mb-4 text-center text-lg">Password saved</div>

              <div className="mb-4 text-center text-sm">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="green"
                  className="w-20 aspect-square mx-auto"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
                <span>
                  Your password has changed, you can now login with new password
                </span>
              </div>
              <div className="mt-4 font-semibold text-sm text-slate-500 text-center">
                Back to{" "}
                <Link
                  className="text-blue-600 hover:underline hover:underline-offset-4"
                  to="/login"
                >
                  Login
                </Link>
              </div>
            </div>
          </section>
        ) : (
          <section className="h-screen flex flex-col md:flex-row justify-center space-y-10 md:space-y-0 md:space-x-16 items-center my-2 mx-5 md:mx-0 md:my-0">
            <div className="p-8 rounded-md bg-white w-fit h-fit flex flex-col text-center justify-center">
              <div className="mb-4 text-center text-lg">Reset Password</div>
              <form onSubmit={handleResetPasswordForm.bind(this)}>
                {errors.global && (
                  <div
                    id="alert-2"
                    className="flex items-center p-4 mb-4 text-red-800 rounded-lg bg-red-50 dark-to-replace:bg-gray-800 dark-to-replace:text-red-400"
                    role="alert"
                  >
                    <svg
                      className="flex-shrink-0 w-4 h-4"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                    </svg>
                    <span className="sr-only">Info</span>
                    <div className="ms-3 text-sm font-medium">
                      {errors.global}
                    </div>
                  </div>
                )}

                <div className="w-[300px]">
                  <input
                    className="text-sm w-full  px-4 py-2 border border-solid border-gray-300 rounded"
                    type="password"
                    id="password"
                    name="password"
                    placeholder="New password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />

                  {errors.hasOwnProperty("password") && (
                    <p className="text-red-500 text-xs text-left italic mt-1">
                      {errors.password}
                    </p>
                  )}
                </div>

                <div className="mt-4 w-[300px]">
                  <input
                    className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded"
                    type="password"
                    id="confirmPassword"
                    name="confirmPassword"
                    placeholder="Confirm password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />

                  {errors.hasOwnProperty("confirmPassword") && (
                    <p className="text-red-500 text-xs text-left italic mt-1">
                      {errors.confirmPassword}
                    </p>
                  )}
                </div>

                <div className="text-center w-full mt-6">
                  <button
                    className="ml-auto bg-blue-600 hover:bg-blue-700 px-4 py-2 text-white uppercase rounded text-xs tracking-wider cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
                    type="submit"
                    disabled={isSending}
                  >
                    Save Password
                  </button>
                </div>
              </form>
              <div className="mt-4 font-semibold text-sm text-slate-500 text-center">
                Back to{" "}
                <Link
                  className="text-blue-600 hover:underline hover:underline-offset-4"
                  to="/login"
                >
                  Login
                </Link>
              </div>
            </div>
          </section>
        )}
      </div>
    </main>
  );
};

export default ResetPasswordPage;
