import React, { useState, useEffect, useRef } from "react";

const Dialog = ({
  isOpen,
  hasCloseBtn = true,
  onClose,
  onSubmit,
  title,
  acceptButtonText,
  children,
  body,
  extraText,
}) => {
  const [isDialogOpen, setDialogOpen] = useState(isOpen);
  const DialogRef = useRef(null);

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit();
    }
    setDialogOpen(false);
  };

  const handleCloseDialog = () => {
    if (onClose) {
      onClose();
    }
    setDialogOpen(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      handleCloseDialog();
    }
  };

  useEffect(() => {
    setDialogOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    const DialogElement = DialogRef.current;

    if (DialogElement) {
      DialogElement.style.display = isDialogOpen ? "flex" : "none";
    }
  }, [isDialogOpen]);

  return (
    <div
      ref={DialogRef}
      onKeyDown={handleKeyDown}
      tabIndex="-1"
      aria-hidden="true"
      className="modal fixed inset-0 flex items-center justify-center z-50"
    >
      <div className="modal-overlay absolute inset-0 bg-black opacity-50"></div>

      <div className="relative w-full max-w-2xl max-h-full">
        <div className="relative bg-white rounded-lg shadow dark-to-replace:bg-gray-700">
          <div className="flex items-start justify-between p-4 border-b rounded-t dark-to-replace:border-gray-600">
            <h3 className="text-xl font-semibold text-gray-900 dark-to-replace:text-white">
              {title}
            </h3>
            <button
              onClick={handleCloseDialog}
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark-to-replace:hover:bg-gray-600 dark-to-replace:hover:text-white"
              data-modal-hide="defaultModal"
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="p-6 space-y-6">
            {children || body}
            {extraText && (
              <div
                dangerouslySetInnerHTML={{ __html: extraText }}
                className="mt-4 text-xs text-neutral-500"
              />
            )}
          </div>
          <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark-to-replace:border-gray-600">
            <button
              onClick={handleSubmit}
              data-modal-hide="defaultModal"
              type="button"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark-to-replace:bg-blue-600 dark-to-replace:hover:bg-blue-700 dark-to-replace:focus:ring-blue-800"
            >
              {acceptButtonText}
            </button>
            {hasCloseBtn && (
              <button
                onClick={handleCloseDialog}
                data-modal-hide="defaultModal"
                type="button"
                className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark-to-replace:bg-gray-700 dark-to-replace:text-gray-300 dark-to-replace:border-gray-500 dark-to-replace:hover:text-white dark-to-replace:hover:bg-gray-600 dark-to-replace:focus:ring-gray-600"
              >
                Cancel
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dialog;
