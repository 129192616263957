/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { onLogoutUser } from "../../utils/auth-helper";
import Tooltip from "../Tooltip";
import { addClass } from "../../utils/css-helper";

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const currentPage = location.pathname.split("/")[1];

  const onLogoutClick = async () => {
    const isLoggedOut = await onLogoutUser();

    if (isLoggedOut) {
      navigate("/login");
    }
  };

  return (
    <>
      <div className="z-30 flex flex-row h-full relative">
        <nav className="flex flex-col fixed justify-between shadow-lg w-20 h-screen bg-white dark-to-replace:bg-gray-800">
          <div className="mt-1 mb-10">
            <a href="/projects">
              <img
                src="/logo.png"
                className="w-10 h-10 mx-auto my-3 object-contain pointer-events-none select-none"
                alt="logo"
              />
            </a>
            <div className="mt-10">
              <ul>
                {/* 1. Home */}
                <li className="my-12 text-center">
                  <Tooltip tooltipText="Home" className="hint--right">
                    <a href="/projects">
                      <span
                        className={`h-6 w-6 text-gray-500 dark-to-replace:text-gray-300 mx-auto hover:text-gray-800 dark-to-replace:hover:text-white transition-colors duration-200 ${addClass(
                          "text-gray-800",
                          currentPage === "projects" ||
                            currentPage === "project"
                        )}`}
                      >
                        <svg
                          width="20"
                          height="20"
                          className="m-auto w-6 h-6"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <path d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z" />
                          <path d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z" />
                        </svg>
                      </span>
                    </a>
                  </Tooltip>
                </li>

                {/* 2. Usage Info */}
                <li className="my-12 text-center">
                  <Tooltip tooltipText="Usage Info" className="hint--right">
                    <a href="/usage-info">
                      <span
                        className={`h-6 w-6 text-gray-500 dark-to-replace:text-gray-300 mx-auto hover:text-gray-800 dark-to-replace:hover:text-white transition-colors duration-200 ${addClass(
                          "text-gray-800",
                          currentPage === "usage-info"
                        )}`}
                      >
                        <svg
                          width="22"
                          height="22"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={2}
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.75 3v11.25A2.25 2.25 0 0 0 6 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0 1 18 16.5h-2.25m-7.5 0h7.5m-7.5 0-1 3m8.5-3 1 3m0 0 .5 1.5m-.5-1.5h-9.5m0 0-.5 1.5m.75-9 3-3 2.148 2.148A12.061 12.061 0 0 1 16.5 7.605"
                          />
                        </svg>
                      </span>
                    </a>
                  </Tooltip>
                </li>

                {/* 3. Shop */}
                <li className="my-12 text-center">
                  <Tooltip tooltipText="Shop" className="hint--right">
                    <a href="/pricing">
                      <span
                        className={`h-6 w-6 text-gray-500 dark-to-replace:text-gray-300 mx-auto hover:text-gray-800 dark-to-replace:hover:text-white transition-colors duration-200 ${addClass(
                          "text-gray-800",
                          currentPage === "pricing"
                        )}`}
                      >
                        <svg
                          width="22"
                          height="22"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={2}
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
                          />
                        </svg>
                      </span>
                    </a>
                  </Tooltip>
                </li>

                {/* 4. Account */}
                <li className="my-12 text-center">
                  <Tooltip tooltipText="Account" className="hint--right">
                    <a href="/account">
                      <span
                        className={`h-6 w-6 text-gray-500 dark-to-replace:text-gray-300 mx-auto hover:text-gray-800 dark-to-replace:hover:text-white transition-colors duration-200 ${addClass(
                          "text-gray-800",
                          currentPage === "account"
                        )}`}
                      >
                        <svg
                          width="22"
                          height="22"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={2}
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                          />
                        </svg>
                      </span>
                    </a>
                  </Tooltip>
                </li>
              </ul>
            </div>
          </div>
          <div className="mb-4 text-center">
            <Tooltip tooltipText="Logout" className="hint--right mx-auto">
              <span className="cursor-pointer" onClick={onLogoutClick}>
                <svg
                  className="w-8 h-8 text-gray-300 fill-current hover:text-red-500"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13 4.00894C13.0002 3.45665 12.5527 3.00876 12.0004 3.00854C11.4481 3.00833 11.0002 3.45587 11 4.00815L10.9968 12.0116C10.9966 12.5639 11.4442 13.0118 11.9965 13.012C12.5487 13.0122 12.9966 12.5647 12.9968 12.0124L13 4.00894Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M4 12.9917C4 10.7826 4.89541 8.7826 6.34308 7.33488L7.7573 8.7491C6.67155 9.83488 6 11.3349 6 12.9917C6 16.3054 8.68629 18.9917 12 18.9917C15.3137 18.9917 18 16.3054 18 12.9917C18 11.3348 17.3284 9.83482 16.2426 8.74903L17.6568 7.33481C19.1046 8.78253 20 10.7825 20 12.9917C20 17.41 16.4183 20.9917 12 20.9917C7.58172 20.9917 4 17.41 4 12.9917Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </span>
            </Tooltip>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Sidebar;
