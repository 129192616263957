// MyContext.js
import React, { createContext, useState } from "react";
import useAwaitableComponent from "../hooks/use-awaitable-component";
import Dialog from "../components/Dialog/index";

// Create the context
const ModalContext = createContext();

// Create a provider component
export function ModalContextProvider({ children }) {
  const [status, execute, resolve, reject, reset] = useAwaitableComponent();
  const [modalInfo, setModalInfo] = useState({
    body: null,
    title: null,
  });

  const isModalOpen = status === "awaiting";

  // usage - resolve and reject to be called by the dialog component which changes the status and once its done then the
  // reset handlereset to be called by the child component to reset the modal status
  // handleopenmodal -> resolve / reject(by dialog based on user action) -> resolved by the child component

  const handleOpenModal = async (newInfo) => {
    // now in awaiting status with modal opened
    try {
      console.log("Modal data:: ", newInfo);
      setModalInfo(newInfo);
      await execute();
    } catch (err) {
      console.error(err);
    }
  };

  const handleOnCloseModal = async () => {
    await reject();
    await reset();
  };

  return (
    <>
      <ModalContext.Provider
        value={{
          modalStatus: status,
          modalReset: reset,
          openModal: handleOpenModal,
        }}
      >
        {children}
      </ModalContext.Provider>
      <Dialog
        isOpen={isModalOpen}
        onSubmit={resolve}
        onClose={handleOnCloseModal}
        acceptButtonText={modalInfo.acceptButtonText}
        title={modalInfo.title}
        extraText={modalInfo.extraText}
      >
        {modalInfo.body}
      </Dialog>
    </>
  );
}

export default ModalContext;
