import React, { useEffect, useState } from "react";
import "./index.css";
import {
  createOrder,
  getLatestPayment,
  validateOrder,
} from "../../api/payment";

const features = [
  { id: 1, name: "Unlimited number of apps" },
  { id: 8, name: "Total Projects" },
  { id: 2, name: "Number of builds" },
  { id: 3, name: "Android app" },
  { id: 4, name: "iOS app" },
  { id: 5, name: "Push Notifications" },
  { id: 6, name: "App Analytics" },
  { id: 7, name: "Publishing to Play Store" },
];

const editionAndFeaturesList = [
  {
    name: "Starter",
    id: "starter",
    price: "₹499",
    features: {
      1: true,
      8: "5",
      3: true,
      2: "10",
      4: "Coming soon",
      5: "Coming soon",
      6: "Coming soon",
      7: false,
    },
  },
  {
    name: "Growth",
    id: "growth",
    price: "₹1999",
    features: {
      1: true,
      3: true,
      8: "10",
      2: "50",
      4: "Coming soon",
      5: "Coming soon",
      6: "Coming soon",
      7: "Coming soon",
    },
  },
  {
    name: "Scale",
    id: "scale",
    price: "₹4599",
    features: {
      1: true,
      8: "30",
      3: true,
      2: "100",
      4: "Coming soon",
      5: "Coming soon",
      6: "Coming soon",
      7: "Coming soon",
    },
  },
];

const CheckIcon = ({ isChecked }) =>
  isChecked ? (
    <svg
      className="w-3 h-3 text-green-500"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 12"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1 5.917 5.724 10.5 15 1.5"
      />
    </svg>
  ) : (
    <svg
      className="w-3 h-3 text-red-500"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
      />
    </svg>
  );

function PricingPage() {
  const [paymentData, setPaymentData] = useState({});

  useEffect(() => {
    // no pack info here, just buying page
    // getPaymentData();
  }, []);

  const getPaymentData = async () => {
    const { data } = await getLatestPayment();
    setPaymentData(data);
  };

  const checkIsSubscribedPlan = (planId) => {
    return String(planId) === paymentData?.planId;
  };

  const handlePayment = async (planId) => {
    const userData = JSON.parse(localStorage.getItem("user") || "{}");
    const [firstName = "", lastName = ""] = userData?.name.split(" ");

    await window.fastspring.builder.tag({ userId: userData?.id });
    await window.fastspring.builder.recognize({
      email: userData.email,
      firstName,
      lastName,
    });
    await window.fastspring.builder.add(planId);
    await window.fastspring.builder.checkout();
  };

  return (
    <div
      id="detailed-pricing"
      className="px-40 mh-4 mb-4 overflow-x-auto m-auto"
    >
      <div className="relative w-full p-4 text-center my-6 bg-blue-600 border border-gray-200 rounded-lg shadow sm:p-8 dark-to-replace:bg-gray-800 dark-to-replace:border-gray-700">
        <h5 className="mb-2 text-3xl font-bold text-white dark-to-replace:text-white">
          Pricing
        </h5>
        <p className="mb-5 text-base text-white sm:text-lg dark-to-replace:text-gray-400">
          Explore our transparent pricing options for straightforward solutions
          tailored to your needs
        </p>
      </div>

      <div className="relative z-10 overflow-hidden w-3/4 m-auto bg-blue-50">
        <div className="grid grid-cols-4 p-4 text-sm font-medium text-gray-900 bg-gray-100 border-t border-b border-gray-200 gap-x-16 dark-to-replace:bg-gray-800 dark-to-replace:border-gray-700 dark-to-replace:text-white">
          <div className="flex items-center">Features</div>
          {editionAndFeaturesList.map((data) => (
            <div key={data.id}>{data.name}</div>
          ))}
        </div>
        {features.map((feature) => (
          <div
            key={feature.id}
            className="grid grid-cols-4 px-4 py-5 text-sm text-gray-700 border-b border-gray-200 gap-x-16 dark-to-replace:border-gray-700"
          >
            <div className="text-gray-500 dark-to-replace:text-gray-400">
              {feature.name}
            </div>
            {editionAndFeaturesList.map((edition) => {
              const value = edition.features[feature.id];

              if (typeof value === "string") {
                return <div key={edition.id}>{value}</div>;
              }

              return (
                <div key={edition.id}>
                  <CheckIcon isChecked={value} />
                </div>
              );
            })}
          </div>
        ))}

        <div className="grid grid-cols-4 px-4 py-5 text-sm text-gray-700 border-b border-gray-200 gap-x-16 dark-to-replace:border-gray-700">
          <div className="text-gray-500 dark-to-replace:text-gray-400"></div>
          {editionAndFeaturesList.map((data) =>
            checkIsSubscribedPlan(data.id) ? (
              <div key={data.id}>
                <a
                  href="#"
                  className="text-white block w-full bg-green-600 hover:bg-green-700 focus:ring-4 focus:ring-green-200 font-medium rounded-lg text-sm px-4 py-2.5 text-center dark-to-replace:focus:ring-green-900"
                >
                  Current Plan
                </a>
              </div>
            ) : (
              !paymentData?.planId && (
                <div key={data.id}>
                  <a
                    onClick={() => handlePayment(data.id)}
                    href="#"
                    className="text-white block w-full bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-200 font-medium rounded-lg text-sm px-4 py-2.5 text-center dark-to-replace:focus:ring-blue-900"
                  >
                    Buy at{" "}
                    <span
                      data-fsc-item-path={data.id}
                      data-fsc-item-total
                    ></span>
                  </a>
                </div>
              )
            )
          )}
        </div>
        {paymentData?.planId && (
          <div className="text-xs text-end relative mt-6">
            *To upgrade or downgrade your plan contact customer support at{" "}
            <a
              className=" font-semibold"
              href="mailto:operations@usecarveapp.com"
            >
              operations@usecarveapp.com
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

export default PricingPage;
