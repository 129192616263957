import React, { useState } from "react";
import { isValidEmail } from "../../utils/auth-helper";
import { Link } from "react-router-dom";
import { forgotPassword } from "../../api/auth";

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [emailSent, setEmailSent] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const handleForgotPasswordForm = async (evt) => {
    evt.preventDefault();

    const currentErrors = validateCredentials(email);
    setErrors(() => ({ ...currentErrors }));

    try {
      setIsSending(true);
      // if no errors then proceed to make request
      if (!Object.keys(errors).length) {
        // forgot pass api call
        await forgotPassword({ email });
        setEmailSent(true);
      }
    } catch (e) {
      setErrors({
        global: e.response?.data?.message,
      });
    } finally {
      setIsSending(false);
    }
  };

  const validateCredentials = (email) => {
    let errors = {};

    if (email === "") {
      errors = Object.assign(errors, {
        email: "This field is required",
      });
    }

    if (!isValidEmail(email)) {
      errors = Object.assign(errors, {
        email: "Invalid email address",
      });
    }

    return errors;
  };

  return (
    <main className="login-page">
      <div className="backdrop">
        {emailSent ? (
          <section className="h-screen flex flex-col md:flex-row justify-center space-y-10 md:space-y-0 md:space-x-16 items-center my-2 mx-5 md:mx-0 md:my-0">
            <div className="p-8 rounded-md bg-white w-fit h-fit flex flex-col text-center justify-center">
              <div className="mb-4 text-center text-lg">Email Sent</div>

              <div className="mb-4 text-center text-sm">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="green"
                  className="w-20 aspect-square mx-auto"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
                <span>Please check your email for verification link</span>
              </div>
              <div className="mt-4 font-semibold text-sm text-slate-500 text-center">
                Back to{" "}
                <Link
                  className="text-blue-600 hover:underline hover:underline-offset-4"
                  to="/login"
                >
                  Login
                </Link>
              </div>
            </div>
          </section>
        ) : (
          <section className="h-screen flex flex-col md:flex-row justify-center space-y-10 md:space-y-0 md:space-x-16 items-center my-2 mx-5 md:mx-0 md:my-0">
            <div className="p-8 rounded-md bg-white w-fit h-fit flex flex-col text-center justify-center">
              <div className="mb-4 text-center text-lg">Forgot Password</div>
              <form onSubmit={handleForgotPasswordForm.bind(this)}>
                {errors.global && (
                  <div
                    id="alert-2"
                    className="flex items-center p-4 mb-4 text-red-800 rounded-lg bg-red-50 dark-to-replace:bg-gray-800 dark-to-replace:text-red-400"
                    role="alert"
                  >
                    <svg
                      className="flex-shrink-0 w-4 h-4"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                    </svg>
                    <span className="sr-only">Info</span>
                    <div className="ms-3 text-sm font-medium">
                      {errors.global}
                    </div>
                  </div>
                )}

                <input
                  className="text-sm w-full min-w-[300px] px-4 py-2 border border-solid border-gray-300 rounded"
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />

                {errors.hasOwnProperty("email") && (
                  <p className="text-red-500 text-xs text-left italic mt-1">
                    {errors.email}
                  </p>
                )}

                <p className="mt-4 text-sm w-[300px]">
                  We’ll send a verification code to this email if it matches an
                  existing account.
                </p>

                <div className="text-center w-full mt-6">
                  <button
                    className="ml-auto bg-blue-600 hover:bg-blue-700 px-4 py-2 text-white uppercase rounded text-xs tracking-wider cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
                    type="submit"
                    disabled={isSending}
                  >
                    Send verification link
                  </button>
                </div>
              </form>
              <div className="mt-4 font-semibold text-sm text-slate-500 text-center">
                Back to{" "}
                <Link
                  className="text-blue-600 hover:underline hover:underline-offset-4"
                  to="/login"
                >
                  Login
                </Link>
              </div>
            </div>
          </section>
        )}
      </div>
    </main>
  );
};

export default ForgotPasswordPage;
