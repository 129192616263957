export function isValidEmail(email) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

export function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

export function isValidApplicationId(appId) {
  // string should be more than 6 characters and not contains anything other than alphanumeric and - and .
  return /^[a-zA-Z0-9_\.]{6,}$/.test(appId);
}

export function isValidAppName(appName) {
  return /^[a-zA-Z0-9_\.]{1,30}$/.test(appName);
}
